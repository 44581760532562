import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Confirm from '@/v1/Modal/Confirm'
import type { ButtonDirectionType, ModalButtonType } from '@/v1/Modal/types'

interface ConfirmModalProps {
  title?: string
  message: string | JSX.Element
  closeable?: boolean
  confirmButton: ModalButtonType
  cancelButton: ModalButtonType
  buttonDirection?: ButtonDirectionType
  onClose?: () => void
}

const ConfirmModal = NiceModal.create(
  ({
    title,
    message,
    closeable = false,
    confirmButton,
    cancelButton,
    buttonDirection = 'row',
    onClose,
  }: ConfirmModalProps) => {
    const modal = useModal()

    const handleConfirm = () => {
      confirmButton.onClick?.()
      modal.hide()
    }

    const handleCancel = () => {
      cancelButton.onClick?.()
      modal.hide()
    }

    const handleClose = () => {
      onClose?.()
      modal.hide()
    }

    return (
      <Confirm
        opened={modal.visible}
        title={title}
        message={message}
        closeable={closeable}
        buttonDirection={buttonDirection}
        confirmButtonText={confirmButton.text}
        cancelButtonText={cancelButton.text}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        onClose={handleClose}
        gaSelectorCancel={cancelButton.gaSelector}
        gaSelectorConfirm={confirmButton.gaSelector}
      />
    )
  },
)

export default ConfirmModal
