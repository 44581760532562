import { z } from 'zod'

export type AnswerType = 'positive' | 'negative'

export enum FeedbackType {
  THUMBS_UP_AND_DOWN = 'THUMBS_UP_AND_DOWN',
  STAR_RATING = 'STAR_RATING',
}

export enum FeedbackContentType {
  cancer_content_detail = 'cancer_content_detail',
  cancer_treatment_detail = 'cancer_treatment_detail',
  hospital_detail = 'hospital_detail',
  plus_report_detail = 'plus_report_detail',
  clinical_note = 'clinical_note',
  hospital_reservation = 'hospital_reservation',
  qna_detail = 'qna_detail',
  qna_ask = 'qna_ask',
  qna_me = 'qna_me',
  qna_ai_answer = 'qna_ai_answer',
}

const feedbackSchema = z.object({
  type: z.string(),
  feedbacks: z.array(
    z.object({
      value: z.string(),
      label: z.string(),
    }),
  ),
})

const feedbackChoicesSchema = z.object({
  type: z.string(),
  reason: z
    .object({
      positive: feedbackSchema,
      negative: feedbackSchema,
    })
    .nullish(),
  positive_rating: z.array(z.number()).nullish(),
  negative_rating: z.array(z.number()).nullish(),
})

const feedbackFormSchema = z.object({
  content_id: z.number().nullable(),
  star_rating: z.number().nullable(),
  thumbs_up: z.boolean().nullable(),
  reason: z.array(z.string()).min(1).nullable(),
  free_feedback: z.string().nullable(),
})

const feedbackFormResponse = z.object({
  id: z.number(),
  content_id: z.number(),
  content_type: z.enum([
    FeedbackContentType.cancer_content_detail,
    FeedbackContentType.cancer_treatment_detail,
    FeedbackContentType.hospital_detail,
    FeedbackContentType.plus_report_detail,
    FeedbackContentType.clinical_note,
    FeedbackContentType.hospital_reservation,
    FeedbackContentType.qna_detail,
    FeedbackContentType.qna_ask,
    FeedbackContentType.qna_me,
  ]),
  star_rating: z.number().nullable(),
  thumbs_up: z.boolean().nullable(),
  reason: z.array(z.string()).nullable(),
  free_feedback: z.string().nullable(),
})

export type FeedbackChoices = z.infer<typeof feedbackChoicesSchema>
export type FeedbackForm = z.infer<typeof feedbackFormSchema>
export type FeedbackPositiveOptions = z.infer<typeof feedbackSchema>
export type FeedbackFormResponse = z.infer<typeof feedbackFormResponse>

export interface FeedbackProps {
  contentId: string
  contentType: FeedbackContentType
  reasons: FeedbackChoices['reason']
}
