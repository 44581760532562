import { useParams } from 'next/navigation'
import useSWR from 'swr'
import { API_PATH } from '@/consts'
import StarRatingFeedback from './StarRatingFeedback'
import ThumbsUpDownFeedback from './ThumbsUpDownFeedback'
import { FeedbackChoices, FeedbackContentType, FeedbackType } from './types'

/**
 * 사용자 피드백을 수집하는 컴포넌트입니다.
 * @param {FeedbackContentType} type - 피드백을 수집할 컨텐츠의 유형
 * @param contentId - 피드백 대상 컨텐츠의 고유 식별자
 * @returns 피드백 유형에 따라 StarRatingFeedback 또는 ThumbsUpDownFeedback을 렌더링
 */
const UserFeedback = ({
  contentId,
  type = FeedbackContentType.cancer_content_detail,
}: {
  contentId?: string
  type: FeedbackContentType
}) => {
  const id = useParams<{ id: string }>().id
  const { data } = useSWR<FeedbackChoices>(
    type ? `${API_PATH.Feedback}${type}/` : null,
  )
  if (!data) return null

  if (data.type === FeedbackType.STAR_RATING)
    return (
      <StarRatingFeedback
        contentId={contentId ?? id}
        contentType={type}
        reasons={data.reason}
      />
    )

  return (
    <ThumbsUpDownFeedback
      contentId={contentId ?? id}
      contentType={type}
      reasons={data.reason}
    />
  )
}

export default UserFeedback
