import clsx from 'clsx'

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  title: string
}

const CancelButton = ({ title, ...rest }: Props) => {
  return (
    <button
      className={clsx(
        'text-gray-70',
        'text-xs',
        'underline',
        'decoration-gray-70',
      )}
      type="button"
      {...rest}
    >
      {title}
    </button>
  )
}

export default CancelButton
