import { forwardRef, ReactNode } from 'react'
import clsx from 'clsx'
import { noop } from '@/utils'
import { filterPropsWithPrefix } from '@/utils/filterPropsWithPrefix'
import { OnChange } from './types'

export * from './types'

interface Props {
  value?: string
  name: string
  disabled?: boolean
  onChange?: OnChange
  children: ReactNode
  defaultChecked?: boolean
}

const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      value,
      name,
      disabled = false,
      onChange = noop,
      children,
      defaultChecked,
      ...restProps
    },
    ref,
  ) => {
    const dataProps = filterPropsWithPrefix(restProps, 'data-')

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = e.target
      onChange?.({ selected: checked, value, event: e })
    }

    return (
      <label
        className="flex items-center form-check-label cursor-pointer"
        {...dataProps}
      >
        <input
          ref={ref}
          id={name}
          className="checked:bg-lavendar-30 focus:outline-none peer hidden"
          type="checkbox"
          name={name}
          value={value}
          defaultChecked={defaultChecked}
          onChange={handleChange}
          disabled={disabled}
          {...restProps}
        />
        <span
          className={clsx(
            'pointer-events-none',
            'flex',
            'justify-center',
            'items-center',
            'rounded-xs',
            'h-5',
            'w-5',
            'border',
            'transition',
            'duration-200',
            'bg-white',
            'text-white',
            'leading-[0.9rem]',
            'peer-checked:border-lavendar-30',
            'peer-checked:bg-lavendar-30',
            'border-gray-60',
          )}
        >
          &#10003;
        </span>

        <span
          className={clsx(
            'pointer-events-none',
            'inline-block',
            'select-none',
            'leading-7',
            'peer-checked:text-gray-100',
            !disabled ? 'text-gray-70' : 'text-gray-60',
          )}
        >
          {children}
        </span>
      </label>
    )
  },
)

Checkbox.displayName = 'Checkbox'
export default Checkbox
