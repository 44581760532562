import { ReactNode } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import LoginPromotion from '@/components/LoginPromotion'

export interface LoginPromotionModalProps {
  imgSrc: string
  heading1: ReactNode
  heading2: ReactNode
  body: string
  gaSelectorKakao: string
  gaSelectorNaver: string
  gaSelectorCancel?: string
  redirectPath: string
}

/* 비로그인 상태인 유저가 Auth를 필요로 하는 곳에 접근하려고 할 때 full screen 모달로 로그인 페이지를 보여 줌*/
const LoginPromotionModal = NiceModal.create(
  ({
    imgSrc,
    heading1,
    heading2,
    body,
    gaSelectorKakao,
    gaSelectorNaver,
    gaSelectorCancel = '',
    redirectPath,
  }: LoginPromotionModalProps) => {
    const modal = useModal()

    const handleClose = () => {
      modal.hide()
    }

    return (
      <LoginPromotion
        opened={modal.visible}
        onClose={handleClose}
        imgSrc={imgSrc}
        heading1={heading1}
        heading2={heading2}
        body={body}
        gaSelectorKakao={gaSelectorKakao}
        gaSelectorNaver={gaSelectorNaver}
        gaSelectorCancel={gaSelectorCancel}
        redirectPath={redirectPath}
      />
    )
  },
)

export default LoginPromotionModal
