'use client'

import { ReactNode } from 'react'
import clsx from 'clsx'
import { KakaoLoginButton, NaverLoginButton } from '@/components/AuthButton'
import CancelButton from '@/components/LoginPromotion/CancelButton'
import useSocialLogin from '@/hooks/useSocialLogin'
import useToggleBodyScroll from '@/hooks/useToggleBodyScroll'
import MaterialSymbol from '@/v1/MaterialSymbol'
import Modal from '@/v1/Modal'

const LoginPromotion = ({
  opened: isModalOpen,
  onClose,
  imgSrc,
  heading1,
  heading2,
  body,
  gaSelectorKakao,
  gaSelectorNaver,
  gaSelectorCancel = '',
  redirectPath,
}: {
  opened: boolean
  onClose?: () => void
  imgSrc: string
  heading1: ReactNode
  heading2: ReactNode
  body: string
  gaSelectorKakao: string
  gaSelectorNaver: string
  gaSelectorCancel?: string
  redirectPath: string
}) => {
  const { handleKakaoLogin, handleNaverLogin } = useSocialLogin()
  useToggleBodyScroll(isModalOpen)

  if (!isModalOpen) return null

  return (
    <Modal>
      <div
        className={clsx(
          'fixed',
          'w-full',
          'h-full',
          'inset-0',
          'z-30',
          'bg-white',
        )}
      >
        <div className="w-full bg-signup-bg-gradient">
          <div className={clsx('flex', 'justify-end', 'w-full', 'h-12')}>
            <button
              data-ga="signup_close"
              type="button"
              onClick={onClose}
              className="px-4"
            >
              <MaterialSymbol name="close" />
            </button>
          </div>
          <div className="flex flex-col items-center pt-[65px]">
            <div>
              <img
                className="drop-shadow-signup mx-auto"
                width={96}
                height={96}
                src={imgSrc}
                alt=""
              />
              <p className="font-bold text-2xl text-center leading-9 mt-6 mb-4 ">
                {heading1}
                <br />
                {heading2}
              </p>
              <p className="text-center text-body whitespace-pre-line leading-7 mb-[108px]">
                {body}
              </p>
            </div>
            <div className="w-full max-w-[436px] mb-6">
              <KakaoLoginButton
                data-ga={gaSelectorKakao}
                text="카카오로 3초만에 시작하기"
                className="max-w-[312px] mx-auto mb-3"
                onClick={() => handleKakaoLogin({ redirectPath })}
              />
              <NaverLoginButton
                data-ga={gaSelectorNaver}
                text="네이버로 3초만에 시작하기"
                className="max-w-[312px] mx-auto"
                onClick={() => handleNaverLogin({ redirectPath })}
              />
            </div>
            <CancelButton
              data-ga={gaSelectorCancel}
              onClick={onClose}
              title="루닛케어 먼저 둘러보기"
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default LoginPromotion
