export function filterPropsWithPrefix(
  props: { [key: string]: unknown } | {},
  prefix: string,
): Record<string, unknown> {
  return Object.fromEntries(
    Object.entries(props as { [key: string]: unknown }).filter(([key]) =>
      key.startsWith(prefix),
    ),
  )
}
