'use client'

import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import useToggleBodyScroll from '@/hooks/useToggleBodyScroll'
import { CancelButton } from '@/v1/Modal/CancelButton'
import { ConfirmButton } from '@/v1/Modal/ConfirmButton'
import Modal from '@/v1/Modal/index'
import Overlay from '@/v1/Modal/Overlay'
import { dialogStyle, modalLayoutStyle } from '@/v1/Modal/styles'
import { ButtonDirectionType } from '@/v1/Modal/types'

export interface ConfirmProps {
  opened: boolean
  title?: string
  message: string | JSX.Element
  closeable: boolean
  confirmButtonText: string
  cancelButtonText: string
  buttonDirection: ButtonDirectionType
  gaSelectorCancel?: string
  gaSelectorConfirm?: string
  onConfirm: () => void
  onCancel: () => void
  onClose: () => void
}

const Confirm = ({
  opened,
  title,
  message,
  closeable,
  confirmButtonText,
  cancelButtonText,
  buttonDirection,
  gaSelectorCancel,
  gaSelectorConfirm,
  onConfirm,
  onCancel,
  onClose,
}: ConfirmProps) => {
  useToggleBodyScroll(opened)

  if (!opened) return null

  return (
    <Modal>
      <Overlay closeable={closeable} onClose={onClose} />
      <div className={clsx(twMerge(modalLayoutStyle), dialogStyle)}>
        {title && <h5 className="pb-2 prose-h5">{title}</h5>}
        <div className={clsx('text-grey-800', 'pb-6', 'whitespace-pre-line')}>
          {message}
        </div>
        <div
          className={clsx(
            'flex',
            'gap-3',
            'w-full',
            { 'flex-row': buttonDirection === 'row' },
            { 'flex-col-reverse': buttonDirection === 'column' },
          )}
        >
          <CancelButton
            text={cancelButtonText}
            onClick={onCancel}
            data-ga={gaSelectorCancel}
          />
          <ConfirmButton
            text={confirmButtonText}
            onClick={onConfirm}
            data-ga={gaSelectorConfirm}
          />
        </div>
      </div>
    </Modal>
  )
}

export default Confirm
