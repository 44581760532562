import { forwardRef, useState, PropsWithChildren } from 'react'
import ReactTextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize'
import { twMerge } from 'tailwind-merge'

interface Props extends TextareaAutosizeProps {
  outlined?: boolean
}

const LimitedTextarea = forwardRef<
  HTMLTextAreaElement,
  PropsWithChildren<Props>
>(({ outlined = false, className = '', ...rest }, ref) => {
  const {
    maxLength = 0,
    placeholder = '',
    disabled = false,
    onChange,
    value = '',
  } = rest
  const [count, setCount] = useState(
    typeof value === 'string' ? value.length : 0,
  )

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(e)
    setCount(e.target.value.length)
  }

  return (
    <>
      <ReactTextareaAutosize
        placeholder={placeholder}
        className={twMerge(
          'w-full',
          'h-full',
          'text-body',
          'leading-7',
          'resize-none',
          'whitespace-pre-wrap',
          'rounded-xl',
          'py-2',
          'px-3',
          'font-medium',
          'min-h-[120px]',
          'placeholder:text-grey-400',
          'focus:border-grey-400',
          'outline-none',
          outlined && 'border border-grey-200',
          disabled && 'bg-grey-100 border-none',
          className,
        )}
        disabled={disabled}
        ref={ref}
        {...rest}
        onChange={handleInput}
      />
      <div className={twMerge('text-right', 'prose-p4', 'text-grey-600')}>
        {count}/{maxLength}
      </div>
    </>
  )
})

LimitedTextarea.displayName = 'LimitedTextarea'

export default LimitedTextarea
